import TextInput from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import FormFooter from 'components/FormFooter';
import QuestionField from 'components/QuestionField';
import useAddressQuestions from 'forms/AboutYouForm/AddressForm/questions';
import {
  PageTitle,
  trackFieldError,
  trackFormTextFieldFocus,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { INPUT_REGEX_ALPHABETICAL_REPLACE } from 'helpers/inputRegexes';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { useRetrieveQuoteDetails } from 'state/formData/retrieveQuote';
import { CsQuestionWithPlaceholder } from 'types/contentStack';
import { PostcodeQuestionField } from './styles';
import useRetrieveLatestQuoteRules from './validation';

type CsRetrieveQuoteFormContent = {
  csPetRetrieveYourQuote: {
    submit_cta_text: string;
    quote_number_question: CsQuestionWithPlaceholder;
    error_messages: {
      missing: string;
    };
  };
};

export const query = graphql`
  query {
    csPetRetrieveYourQuote {
      submit_cta_text
      quote_number_question {
        main_details {
          explanatory_text
          placeholder
          alert_text
          question_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
        }
        error_messages {
          missing
        }
      }
    }
  }
`;

const RetrieveQuoteReferenceForm: React.FC<{
  moveNext: () => void;
}> = ({ moveNext }) => {
  const { submit_cta_text, quote_number_question } = useStaticQuery<
    CsRetrieveQuoteFormContent
  >(query).csPetRetrieveYourQuote;
  const [retrieveQuoteDetails, updateRetrieveQuoteDetails] = useRetrieveQuoteDetails();
  const { processQuestionWithOptionalPlaceholder } = useQuestionProcessor(
    PageTitle.RetrieveQuoteReference
  );

  const quoteNumberQuestion = processQuestionWithOptionalPlaceholder(
    quote_number_question
  );
  const postcodeQuestion = useAddressQuestions().manual.postcode;

  const formErrorRules = useRetrieveLatestQuoteRules();

  const { getError, showValidation, validateOnSubmit } = useValidation(
    retrieveQuoteDetails,
    formErrorRules,
    trackFieldError
  );

  return (
    <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
      <QuestionField question={quoteNumberQuestion} errorText={getError('quoteNumber')}>
        <TextInput
          id="quoteNumber"
          placeholder={quoteNumberQuestion.placeholder}
          value={retrieveQuoteDetails.quoteNumber}
          onChange={(e) => {
            updateRetrieveQuoteDetails({
              quoteNumber: e.target.value
                .replace(INPUT_REGEX_ALPHABETICAL_REPLACE, '')
                .substring(0, 14)
                .toUpperCase()
                .trim(),
            });
          }}
          onBlur={() => {
            showValidation('quoteNumber');
          }}
          onFocus={trackFormTextFieldFocus('Quote number')}
        />
      </QuestionField>
      <PostcodeQuestionField
        question={postcodeQuestion}
        errorText={getError('customerPostcode')}>
        <TextInput
          id="customerPostcode"
          placeholder={postcodeQuestion.placeholder}
          value={retrieveQuoteDetails.customerPostcode}
          onChange={(e) => {
            updateRetrieveQuoteDetails({
              customerPostcode: e.target.value
                .replace(INPUT_REGEX_ALPHABETICAL_REPLACE, '')
                .substring(0, 8)
                .toUpperCase()
                .trim(),
            });
          }}
          onBlur={() => {
            showValidation('customerPostcode');
          }}
          onFocus={trackFormTextFieldFocus('Customer postcode')}
        />
      </PostcodeQuestionField>
      <FormFooter
        contentColumns={{ desktop: 8 }}
        moveNextButton={{
          text: submit_cta_text,
          onClick: () =>
            trackTextButtonClick(PageTitle.RetrieveQuoteReference, submit_cta_text),
        }}
        pageTitle={PageTitle.RetrieveQuoteReference}
      />
    </form>
  );
};

export default RetrieveQuoteReferenceForm;
